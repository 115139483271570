import React from 'react'
import styled from '@emotion/styled'
import { Container } from 'theme-ui'
import CardList from '@components/CardList'
import Card from '@components/Card'
import { Section, CategoryTitle } from '@styles/globalComponents'

const CategoryPosts = ({ posts, ...props }) => {
  return (
    <ColorStrip nowrap={props.nowrap}>
      <CategorySection>
        <Container>
          {props.category && (
            <CategoryTitle line>{props.category}</CategoryTitle>
          )}
          <CardList>
            {posts.map(({ node: post }) => (
              <Card
                key={post.id}
                {...post}
                {...props}
                basePath={props.basePath}
              />
            ))}
          </CardList>
        </Container>
      </CategorySection>
    </ColorStrip>
  )
}

const CategorySection = styled(Section)`
  padding: 3em 1.5em 2em;
`

// ColorStrip only applies to divs displaying three posts maximum
const ColorStrip = styled.div`
  ${(props) =>
    props.nowrap &&
    `
    position: relative;

    &::after {
      content: '';
      // background-color: #FFF2EA;
      height: 60%;
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: -1;
    }
  `}
`

export default CategoryPosts
